<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>交接班记录</h1>
      </el-header>
      <el-main class="scrollable-main">
    <div ref="formContainer" class="form-container">
      <!-- 表单内容 -->
      <el-form label-width="100px">
        <el-form-item label="日期" prop="date">
          <el-date-picker
              v-model="form.date"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="班次" prop="type" class="custom-form-item">
          <el-select clearable v-model="form.shift" placeholder="请选择班次">
            <el-option label="中班" value="中班"></el-option>
            <el-option label="晚班" value="晚班"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="交班人" prop="handoverPerson">
          <el-input v-model="form.handoverPerson" placeholder="请输入交班人姓名" />
        </el-form-item>

        <el-form-item label="接报修数" prop="reportedItemsCount">
          <el-input-number
              v-model="form.reportedItemsCount"
              :min="0"
              placeholder="请输入本班接报修项目数"
          />
        </el-form-item>

        <el-form-item label="完成数" prop="completedCount">
          <el-input-number
              v-model="form.completedCount"
              :min="0"
              placeholder="请输入完成数"
          />
        </el-form-item>

        <el-form-item label="报修内容">
          <div v-for="(item, index) in form.repairItems" :key="index" class="repair-item">
            <el-form-item label="报修时间">
              <el-time-picker
                  v-model="item.time"
                  :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59'
                  }"
                  placeholder="任意时间点">
              </el-time-picker>
            </el-form-item>

            <el-form-item label="车间">
              <el-input v-model="item.workshop" placeholder="请输入车间" />
            </el-form-item>

            <el-form-item label="设备">
              <el-input v-model="item.device" placeholder="请输入设备" />
            </el-form-item>

            <el-form-item label="故障现象">
              <el-input v-model="item.failureType" placeholder="请输入故障现象" />
            </el-form-item>

            <el-form-item label="维修措施">
              <el-input v-model="item.repairMeasures" placeholder="请输入维修措施" />
            </el-form-item>
            <el-form-item label="">
              <el-radio v-model="item.radio" label="1">作业结束</el-radio>
              <el-radio v-model="item.radio" label="2">临时处理</el-radio>
              <el-radio v-model="item.radio" label="3">未完成</el-radio>
            </el-form-item>
            <el-form-item label="未完成原因" prop="uncompletedReason" v-if="item.radio === '3'">
              <el-input
                  type="textarea"
                  v-model="item.uncompletedReason"
                  placeholder="请输入未完成原因"
                  rows="4"
              />
            </el-form-item>
            <el-button type="danger" @click="removeRepairItem(index)">删除</el-button>
            <el-divider></el-divider>
          </div>
          <el-button type="primary" @click="addRepairItem">添加报修项</el-button>
        </el-form-item>

        <el-form-item label="需要跟进项目" prop="uncompletedReason">
          <el-input
              type="textarea"
              v-model="form.needFollow"
              placeholder="请输入需要跟进项目"
              rows="4"
          />
        </el-form-item>

        <el-form-item>
<!--          <el-button type="primary" @click="generateImage">提交</el-button>-->
          <el-button type="primary" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
      </el-main>
<!--      <el-footer class="fixed-footer">-->
<!--        <el-row type="flex" justify="center">-->
<!--          <el-col :span="8" class="footer-item">-->
<!--            <router-link to="/mobile">首页</router-link>-->
<!--          </el-col>-->
<!--          <el-col :span="8" class="footer-item">-->
<!--            <router-link to="/mequipment">设备知识库</router-link>-->
<!--          </el-col>-->
<!--          <el-col :span="8" class="footer-item">-->
<!--            <router-link to="/mperson">个人信息</router-link>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-footer>-->
    </el-container>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
  name: "MChangeRecord",
  data() {
    return {
      form: {
        date: '',
        handoverPerson: '',
        receivingPerson: '',
        reportedItemsCount: 0,
        completedCount: 0,
        shift:'',
        repairItems: [{ time: '', workshop: '', device: '', failureType: '', repairMeasures: '', uncompletedReason: '', radio: '1' }], // 初始化报修项内容
        needFollow: '',
      },
      // imageSrc: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        },]
      },
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/mobile');
    },
    addRepairItem() {
      this.form.repairItems.push({ time: '', workshop: '', device: '', failureType: '', repairMeasures: '', uncompletedReason: '', radio: '1' });
    },
    removeRepairItem(index) {
      this.form.repairItems.splice(index, 1);
    },
    // generateImage() {
    //   const formContainer = this.$refs.formContainer;
    //
    //   // 临时隐藏按钮
    //   const buttons = formContainer.querySelectorAll('button');
    //   buttons.forEach(button => button.style.display = 'none');
    //
    //   html2canvas(formContainer).then(canvas => {
    //     // 将 canvas 转换为 Data URL
    //     const imgData = canvas.toDataURL('image/png');
    //
    //     // 设置生成的图片
    //     this.imageSrc = imgData;
    //
    //     // 可以将图片数据发送到服务器或保存本地
    //     // 示例：上传到服务器
    //     this.uploadImage(imgData);
    //   });
    // },
    // // 示例方法：上传图片到服务器
    // uploadImage(imgData) {
    //   this.request.post("/files/uploadimg", imgData)
    //       .then(response => {
    //         this.$message.success("提交成功")
    //         console.log('上传成功', response);
    //       })
    //       .catch(error => {
    //         this.$message.error("提交失败")
    //         console.error('上传失败', error);
    //       });
    // },
    submitForm() {
      // 收集表单内容
      const formData = {
        date: this.form.date,
        shift: this.form.shift,
        handoverPerson: this.form.handoverPerson,
        receivingPerson: this.form.receivingPerson,
        reportedItemsCount: this.form.reportedItemsCount,
        completedCount: this.form.completedCount,
        repairItems: this.form.repairItems,
        needFollow: this.form.needFollow,
      };

      const formattedTime1 = new Intl.DateTimeFormat('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false
      }).format(new Date(formData.date));

      // 格式化为 Markdown 文本
      let markdownContent = `# 交接班记录\n\n`;
      markdownContent += `>**日期:** ${formattedTime1}\n`;
      markdownContent += `>**班次:** ${formData.shift}\n`;
      markdownContent += `>**交班人:** ${formData.handoverPerson}\n`;
      markdownContent += `>**接报修数:** ${formData.reportedItemsCount}\n`;
      markdownContent += `>**完成数:** ${formData.completedCount}\n\n`;

      markdownContent += `## 本班接报修内容:\n`;
      formData.repairItems.forEach((item, index) => {



        const formattedTime = new Intl.DateTimeFormat('zh-CN', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }).format(new Date(item.time));

        markdownContent += `\n>**报修项目 ${index + 1}:**\n`;
        markdownContent += `>时间: ${formattedTime}\n`;
        markdownContent += `>车间: ${item.workshop}\n`;
        markdownContent += `>设备: ${item.device}\n`;
        markdownContent += `>故障现象: ${item.failureType}\n`;
        markdownContent += `>维修措施: ${item.repairMeasures}\n`;
        markdownContent += `>状态: ${item.radio === '1' ? '作业结束' : '未完成'}\n`;

        if (item.radio === '2') {
          markdownContent += `>未完成原因: ${item.uncompletedReason}\n`;
        }
      });

      markdownContent += `\n## 需要跟进项目:\n${formData.needFollow}\n`;

      // 发送数据到后端
      this.request.post("/wechat/sendMarkDownTextMsg", { content: markdownContent })
          .then(response => {
            this.$message.success("提交成功");
            this.goToHome();
          })
          .catch(error => {
            this.$message.error("提交失败");
            console.error('提交失败', error);
          });
    },

  }
};
</script>

<style scoped>


.image-container {
  margin-top: 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.menu-item {
  font-size: 25px; /* 设置菜单栏项的字体大小 */
  margin-top: 25px;
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
